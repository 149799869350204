@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* th:first-child, td:first-child
{
  position:sticky;
  left:0px;
  background-color:grey;
} */
/* .thead tr th{
    position: sticky!important; 
    top: 0!important; 
} */
.pointer:hover{
    cursor: pointer;
}

.create-account{ 
  cursor: pointer; 
  transition: all 0.5s;
}
.create-account:hover{ 
  opacity: 0.3;
  box-shadow: 1px 1px 1px gray;
}

/*SideBar*/

:root {
    --header-height: 3rem;
    --nav-width: 68px;
    /* --first-color: #212529; */
    --first-color: #343a40;
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100;

    --yellow: #feb60a;
	--red: #ff0062;
	--blue: #00dbf9;
	--violet: #da00f7;
}
.body {
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 107vh;
	background-color: #44ab53;
    background-image: linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(0,153,212,0) calc(15% + 100px), rgba(0,99,138,0) calc(85% + 100px), rgba(0,0,0,0.15) 100%);
}

.container-load {
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-load > div {
	width: 3vw;
	height: 3vw;
	border-radius: 100%;
	margin: 2vw;
	background-image: linear-gradient(145deg, rgba(255,255,255,0.5) 0%, rgba(0,0,0,0) 100%);
	-webkit-animation: __bounce 1.5s 0.5s linear infinite;
	        animation: __bounce 1.5s 0.5s linear infinite;
}

.yellow {
	background-color: #feb60a;
	background-color: var(--yellow);
}

.red {
	background-color: #ff0062;
	background-color: var(--red);
	-webkit-animation-delay: 0.1s!important;
	        animation-delay: 0.1s!important;
}

.blue {
	background-color: #00dbf9;
	background-color: var(--blue);
	-webkit-animation-delay: 0.2s!important;
	        animation-delay: 0.2s!important;
}

.violet {
	background-color: #da00f7;
	background-color: var(--violet);
	-webkit-animation-delay: 0.3s!important;
	        animation-delay: 0.3s!important;
}

@-webkit-keyframes __bounce {
	0%, 50%, 100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	25% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		-webkit-filter: blur(3px);
		        filter: blur(3px);
	}
	75% {
		-webkit-filter: blur(3px);
		        filter: blur(3px);
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);
	}
}

@keyframes __bounce {
	0%, 50%, 100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
		-webkit-filter: blur(0px);
		        filter: blur(0px);
	}
	25% {
		-webkit-transform: scale(0.6);
		        transform: scale(0.6);
		-webkit-filter: blur(3px);
		        filter: blur(3px);
	}
	75% {
		-webkit-filter: blur(3px);
		        filter: blur(3px);
		-webkit-transform: scale(1.4);
		        transform: scale(1.4);
	}
}

*,
::before,
::after {
    box-sizing: border-box
}

body {
    position: relative;
    margin: 3rem 0 0 0;
    margin: var(--header-height) 0 0 0;
    padding: 0 1rem;
    font-family: 'Nunito', sans-serif;
    font-family: var(--body-font);
    font-size: 1rem;
    font-size: var(--normal-font-size);
    transition: .5s;
    /* background: rgb(2,0,36)!important; */
    /* background: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(58,108,158,1) 22%, rgba(0,212,255,1) 100%)!important;  */
    /* background: linear-gradient(360deg, rgba(2,0,36,1) 0%, rgba(58,108,158,1) 22%, rgba(0,212,255,1) 100%)!important;  */
    /* background-color: rgb(177, 177, 177)!important; */
    /* background: rgb(137,226,86);
    background: radial-gradient(circle at top 50% left 60%, rgba(137,226,86,1) 30%, rgba(72,120,44,1) 85%);  */
    
    /* background: rgb(252,244,229);
    background: linear-gradient(90deg, rgb(143, 142, 140) 8%, rgb(244, 239, 232) 55%); */
    /* background: rgb(222,222,222);
background: radial-gradient(circle, rgba(222,222,222,1) 0%, rgba(255,255,255,1) 100%); */
    /* background-color: #4d832e!important; */
    
    /* background: linear-gradient(180deg, rgba(140, 161, 128, 0.69) 0%, rgb(181, 212, 163) 90%); */
    background-attachment: fixed!important;
    background-repeat: no-repeat!important;

    font-family: Optima, Geneva, sans-serif!important;

}

a {
    text-decoration: none!important;
}

.header {
    width: 100%;
    height: 3rem;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    /* background-color: var(--white-color); */
    z-index: 9999;
    /* z-index: var(--z-fixed); */
    /* background: rgb(43,238,240); */
    /* background: linear-gradient(0deg, rgba(43,238,240,1) 0%, rgba(202,233,237,1) 50%);  */
    /* background-color: #464945; */
    background-color: #44ab53;
    /* background-color: #47b805; */
    /* background-color: rgba(0,212,255,1); */
    /* background-color: #e2e0eb00; */
    border-bottom: 1px solid #489a18;
    box-shadow: 1px 1px 10px 1px gray;
}

.header_toggle {
    color: #343a40;
    color: var(--first-color);
    font-size: 1.5rem;
    cursor: pointer;
    color: #F7F6FB;
    color: var(--white-color);
}

.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}

.header_img img {
    width: 40px
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: 68px;
    width: var(--nav-width);
    height: 100vh;
    background-color: #343a40;
    background-color: var(--first-color);
    /* padding: .5rem 1rem 0 0; */
    padding: .5rem 0 0 0;
    transition: .5s;
    z-index: 10000;
    box-shadow: 1px 1px 20px 1px gray;
    /* z-index: var(--z-fixed) */
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content;
    align-items: center;
    grid-column-gap: 1rem;
    -webkit-column-gap: 1rem;
            column-gap: 1rem;
    padding: .5rem 0 .5rem 1.5rem
}


.nav_logo {
    margin-bottom: 2rem
}

._nav_logo{
    display:none
}


.nav_logo-icon {
    font-size: 1.25rem;
    color: #F7F6FB;
    color: var(--white-color)
}

.nav_logo-name {
    color: #F7F6FB;
    color: var(--white-color);
    font-weight: 700
}

.nav_link {
    position: relative;
    color: #AFA5D9;
    color: var(--first-color-light);
    margin-bottom: 1.5rem;
    transition: .3s
}

.nav_link:hover {
    color: #F7F6FB;
    color: var(--white-color)
}

.nav_icon {
    font-size: 1.25rem
}

.show {
    left: 0
}

.body-pd {
    padding-left: calc(68px + 1rem);
    padding-left: calc(var(--nav-width) + 1rem)
}

.active {
    color: #F7F6FB;
    color: var(--white-color)
}


.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: #F7F6FB;
    background-color: var(--white-color)
}


.carousel-item.active::before {
    content: none;
}

.height-100 {
    height: 100vh
}

.res-per{
    text-align: start!important;
}

.res-w100{
    width: 100%;
}

.pdr{
    padding-right: 3rem;
}

.menu-icons .nav_link div{
    width: 2.5rem;
    text-align: left;
}

#w2bcv{
    display: none;
}

#_w2bcv{
    display: block;
}

.border-left-top{
    border-top: 1px solid #dee2e6!important;
    border-left: none!important;
}

/*INPUT DRAG AND DROP*/
/* .files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
} */
/* 
@media screen and (min-width: 500px) {
    .files input {
        padding: 120px 0px 85px 30%;
    }
}

@media screen and (min-width: 650px) {
    .files input {
        padding: 120px 0px 85px 35%;
    }
} */

@media screen and (min-width: 768px) {
    body {
        margin: calc(3rem + 1rem) 0 0 0;
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(68px + 2rem);
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        height: calc(3rem + 1rem);
        height: calc(var(--header-height) + 1rem);
        padding: 0 2rem 0 calc(68px + 2rem);
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem)
    }

    .header_img {
        width: 40px;
        height: 40px
    }

    .header_img img {
        width: 45px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 0 0 0
        /* padding: 1rem 1rem 0 0 */
    }

    .show {
        width: calc(68px + 156px);
        width: calc(var(--nav-width) + 156px)
    }

    .body-pd {
        padding-left: calc(68px + 188px);
        padding-left: calc(var(--nav-width) + 188px)
    }

    .res-per{
        text-align: end!important;
    }

    .res-w100{
        width: auto;
    }

    .pdr{
        padding-right: 15rem;
    }
    
    .menu-icons .nav_link div{
        text-align: center;
    }

    .nav_logo{
        display: none;
    }

    ._nav_logo {
        display: grid;
        grid-template-columns: -webkit-max-content -webkit-max-content;
        grid-template-columns: max-content max-content;
        align-items: center;
        grid-column-gap: 1rem;
        -webkit-column-gap: 1rem;
                column-gap: 1rem;
        padding: .5rem 0 .5rem 1.5rem;
        margin-bottom: 2rem
    }

    #w2bcv{
        display: block;
    }
    
    #_w2bcv{
        display: none;
    }

    /* .files input {
        padding: 120px 0px 85px 35%;
    } */

    .border-left-top{
        border-left: 1px solid #dee2e6!important;
        border-top: none!important;
    }

}

/* @media screen and (min-width: 1280px) {
    .files input {
        padding: 120px 0px 85px 42%;
    }
} */

.bg-card-header{
    background-color: #212529!important;
}

/*UTILIDADES*/
.btn-dark:hover {
    /* color: #212529!important;
    background-color: #fff!important;
    border-color: #1a1e21!important; */
    color: #fff!important;
    background-color: #212529bf!important;
    border-color: #1a1e21!important;
}

.table-responsive table thead tr th{
    text-align: center;
    vertical-align: middle;
}

.table-responsive table tbody tr td,th{
    text-align: center;
    vertical-align: middle;
}


.card{
    background-color: #f5f5f5!important;
    /* background-color: #ebfff1!important; */
    /* border: none!important; */
}

.headers-inputs{
    background: #d4d4d4!important;
    color: #5c5c5c!important;
    border-radius: .3rem!important;
}


.pro-player:before{
    content: "contenido";
    position: relative;
    top: -2px;
    left: 0;
}

/* 
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
 }
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: " Drag & Drop File";
    text-align: center;
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    width: 100%;
    background-repeat: no-repeat;
    font-weight: bold;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " ";
    display: block;
    margin: 0 auto;
    color: black;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
} */

.files2 {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
    background-color: #ffffff;
}
.files input {
    opacity: 0!important;
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}
.files input:focus{     outline: 2px dashed #92b0b3;  outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #92b0b3;
 }
.files{ position:relative}
.files:after {  
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 90px;
    right: 0;
    height: 100px;
    content: " ";
    background-image: url(/static/media/upload.8b06e3e5.svg);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
    opacity: 0.6;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;  pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: "Upload your file here. ";
    display: block;
    margin: 0 auto;
    color: #000000;
    font-weight: bold;
    text-transform: capitalize;
    text-align: center;
}

.transparent{
    background: transparent!important;
}


.zindex-10000{
    z-index: 100000!important;
}

.swal2-container{
    z-index: 10000!important;
}

.modal.fade.show{
    width: 100%!important;
}

.filter-gray{
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
    opacity: .5;
}

.border-change{
    border-top: 2px solid rgb(230, 230, 230);
    border-bottom: 2px solid rgb(230, 230, 230);
    border-right: none;
    border-left: none;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@media (min-width: 768px) {
    
    .border-change{
        border-top: none;
        border-bottom: none;
        border-right: 2px solid rgb(230, 230, 230);
        border-left: 2px solid rgb(230, 230, 230);
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
}


/*Main*/

.principal{
    display: block;
    position: absolute;
    left: 0;
    top: -130px;
    /* top: 0; */
    margin: 0 auto;
    width: 100%;
    /* height: 100vh; */
}

/*Banner*/
.banner{
    height: auto;
    /* background: rgba(110, 180, 70, 0.5) url("./img/anuncio6.jpg") center top no-repeat; */
    /* background: #6eb4466b  url("./img/anuncio6.jpg") center top no-repeat; */
    /* background: url("./img/anuncio6.png") center top no-repeat; */
    background: linear-gradient(45deg, rgba(24, 24, 24,0.8), rgba(105, 105, 105,0.8)), url(/static/media/anuncio6.75832047.png) center top no-repeat;;
    /* background: linear-gradient(180deg, rgba(110, 180, 70, 0.8), rgba(110, 180, 70, 0.8)), url("./img/anuncio6.png") center top no-repeat; */
    /* background-attachment: scroll, scroll; */
    /* background-size: contain; */
    background-size: cover;
    position: relative;
    background-repeat: repeat-y;
    background-color: #156034;
  }
/* .banner{
    height: auto;
    background: url("./img/anuncio6.png") center top no-repeat;
    background-size: contain;
    position: relative;
    background-repeat: repeat-y;
    background-color: #156034;
  } */
  .banner-pro{
    height: 100vh;
    /* background: rgba(110, 180, 70, 0.5) url("./img/anuncio6.jpg") center top no-repeat; */
    /* background: #6eb4466b  url("./img/anuncio6.jpg") center top no-repeat; */
    background: url(/static/media/anuncio6.75832047.png) center top no-repeat;
    /* background: linear-gradient(180deg, rgba(110, 180, 70, 0.8), rgba(110, 180, 70, 0.8)), url("./img/anuncio6.png") center top no-repeat; */
    /* background-attachment: scroll, scroll; */
    background-size: contain;
    /* background-size: cover; */
    position: relative;
    background-repeat: repeat-y;
    /* background-color: #1077CB; */
  }
  /* 
  @media (min-width: 768px) {
    .banner{ */
      /* height: 800px; */
    /* }
  } */
  
  /* @media (min-width: 1280px) {
    .banner{ */
      /* height: auto; */
    /* }
  } */
  
  
  
  .banner-2{
    /* background-color: #25705f; */
    background-color:#6eb446;
    padding: 100px 0;
  }
  
  .banner-sw{
    background: linear-gradient(180deg, rgba(24, 24, 24, 0.521), #50743b6b), url("https://payments.labeldictate.com/storage/intro-bg.jpg") center top no-repeat;
    /* background: linear-gradient(45deg, rgba(24, 24, 24, 0.521), #74757350), url("https://licencias.test/storage/intro-bg.jpg") center top no-repeat; */
    background-attachment: scroll, scroll;
    background-size: auto, auto;
    background-size: cover;
    position: relative;
    /* background-color: #74757396; */
  }
  
  
  .banner-encuentra{
    background: url(/static/media/encuentra.f7d0bebe.png) center top no-repeat;
    background-attachment: scroll, scroll;
    background-size: auto, auto;
    background-size: cover;
    position: relative;
    height: 400px;
    /* background-color: #74757396; */
  }
  
  /*Animaciones*/

#anime{
    -webkit-animation: 1s infinite alternate anime;
            animation: 1s infinite alternate anime;
}
#anime2{
    -webkit-animation: 1s infinite alternate anime;
            animation: 1s infinite alternate anime;
}
  
  @-webkit-keyframes anime {
    from {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(0,0);
              transform: translate(0,0);
    }
  
    to {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(22px,0);
              transform: translate(22px,0);
   }
  }
  
  @keyframes anime {
    from {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(0,0);
              transform: translate(0,0);
    }
  
    to {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(22px,0);
              transform: translate(22px,0);
   }
  }
  
  @-webkit-keyframes anime2 {
    from {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(0,0);
              transform: translate(0,0);
    }
  
    to {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(22px,0);
              transform: translate(22px,0);
   }
  }
  
  @keyframes anime2 {
    from {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(0,0);
              transform: translate(0,0);
    }
  
    to {
      /* transition: ease-in-out all 0.3s; */
      -webkit-transform: translate(22px,0);
              transform: translate(22px,0);
   }
  }

.blue-arrow svg{
    color: #BDD0E6;
}
  
.font-size-5rem{
    font-size: 5rem;
}
  
.bg-carrousel{
    border-radius: 10px;
    border: 1px solid #6f6d6d7a;
}

/************************/
.alert-hidden {
    opacity: 0;
    transition: all 250ms linear 2s; 
  }

.w-10rem{
    width: 10rem!important;
}

.w-50-100{
    width: 20%!important;
}

.l-40-46{
    left: 45%!important;
    width: 10%!important;
}

@media (min-width: 768px) {
    .w-50-100{
        width: 70%!important;
    }

    .l-40-46{
        left: 34%!important;
        width: 35%!important;
    }
}

.table-w-100{
    width: 100%!important;
}

.collapse.show{
    width: auto;
    /* width: 100%!important; */
}

.mantenimiento{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    border: 1px solid #2427277c;
    background-color: #ffffffce;
    z-index: 9999999;
  }

.card-main{
    background: #f5f5f5!important;
    border: 1px solid #e3e3e3!important;
}

.card-padding

#anime{
    -webkit-animation: .7s infinite alternate anime;
            animation: .7s infinite alternate anime;
}
  
@keyframes anime {
    from {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    to {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: translate(0,22px);
                transform: translate(0,22px);
    }
}

@keyframes anime2 {
    from {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    to {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: translate(22px,0);
                transform: translate(22px,0);
    }
}

.gif_lado_lado{
    -webkit-animation: gif_lado_lado 1s infinite alternate linear;
            animation: gif_lado_lado 1s infinite alternate linear;
    /* animation: 2s infinite alternate gif_lado_lado; */
}

@-webkit-keyframes gif_lado_lado {
    from {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: rotate(-30deg);
                transform: rotate(-30deg)
    }
    to {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }
}

@keyframes gif_lado_lado {
    from {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: rotate(-30deg);
                transform: rotate(-30deg)
    }
    to {
        /* transition: ease-in-out all 0.3s; */
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }
}

.like{
    -webkit-animation: myAnim 1.8s ease 0s 1 normal forwards;
            animation: myAnim 1.8s ease 0s 1 normal forwards;
}

@-webkit-keyframes myAnim {
	0% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		opacity: 1;
		-webkit-transform: translateY(-45px);
		        transform: translateY(-45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-24px);
		        transform: translateY(-24px);
	}

	65% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}

	82% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-6px);
		        transform: translateY(-6px);
	}

	93% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-4px);
		        transform: translateY(-4px);
	}

	25%,
	55%,
	75%,
	87% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}

	100% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		opacity: 1;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}

@keyframes myAnim {
	0% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		opacity: 1;
		-webkit-transform: translateY(-45px);
		        transform: translateY(-45px);
	}

	24% {
		opacity: 1;
	}

	40% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-24px);
		        transform: translateY(-24px);
	}

	65% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}

	82% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-6px);
		        transform: translateY(-6px);
	}

	93% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: translateY(-4px);
		        transform: translateY(-4px);
	}

	25%,
	55%,
	75%,
	87% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}

	100% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		opacity: 1;
		-webkit-transform: translateY(0px);
		        transform: translateY(0px);
	}
}
.onOff{
    -webkit-animation: onOff 1s infinite alternate linear;
            animation: onOff 1s infinite alternate linear

}

@-webkit-keyframes onOff {
    from {
        color: red;
    }

    to {
        color: green;
    }
}

@keyframes onOff {
    from {
        color: red;
    }

    to {
        color: green;
    }
}

/*Estilos para welcome (bienvenida primer usuario)*/
.loader-cotizador{
	position: fixed;
	left: 0px;
	top:0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	opacity: .75;
	/* background: rgb(249,249,249); */
    background: rgb(48, 46, 46);
	z-index: 10000!important;
}

.elemento-resaltante::before{
    color: red;
    font-family: "Font Awesome 5 Free";
    content: '\f0a6';
    position: absolute;
    bottom: 0;
    font-size: 100px;
    height: 10px;
    z-index: 10002;
    -webkit-animation: .7s infinite alternate anime;
            animation: .7s infinite alternate anime;
}

.elemento-resaltante2::before{
    color: red;
    font-family: "Font Awesome 5 Free";
    content: '\f0a6';
    position: absolute;
    bottom: 30px;
    left: 40%;
    font-size: 100px;
    height: 10px;
    z-index: 10002;
    -webkit-animation: .7s infinite alternate anime;
            animation: .7s infinite alternate anime;
}

.elemento-resaltante-filtro{
    z-index: 10001 !important;
    position: relative;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem 1rem;
}

._elemento-resaltante-filtro-btn::before{
    color: rgb(240, 61, 16);
    font-family: "Font Awesome 5 Free";
    content: '\f0a5';
    position: absolute;
    top: -35px;
    left: 45%;
    font-size: 100px;
    z-index: 10003;
    -webkit-animation: 4s infinite normal tada;
            animation: 4s infinite normal tada;
}

._elemento-filtrar-btn::before{
    color: rgb(240, 61, 16);
    font-family: "Font Awesome 5 Free";
    content: '\f0a6';
    position: absolute;
    bottom: -120px!important;
    left: 40%!important;
    font-size: 100px;
    /* z-index: 10003; */
    -webkit-animation: .7s infinite alternate anime;
            animation: .7s infinite alternate anime;
}

@media (min-width: 768px) {
    .elemento-resaltante::before{
        color: red;
        font-family: "Font Awesome 5 Free";
        content: '\f0a4';
        position: absolute;
        top: -30px;
        left: 5%;
        /* font-size: 100px; */
        /* z-index: 10002; */
        box-shadow: 1rem 4rem 2rem 10px black;
        -webkit-animation: .7s infinite alternate anime2;
                animation: .7s infinite alternate anime2;
        
    }
    .elemento-resaltante2::before{
        color: red;
        font-family: "Font Awesome 5 Free";
        content: '\f0a6';
        position: absolute;
        /* font-size: 100px; */
        /* z-index: 10002; */
        box-shadow: 1rem 4rem 2rem 10px black;
        -webkit-animation: .7s infinite alternate anime;
                animation: .7s infinite alternate anime;
        
    }

    ._elemento-resaltante-filtro-btn::before{
        top: -35px;
        left: 20%;
        
    }

    ._elemento-filtrar-btn::before{
        content: '\f0a5';
        bottom: -50px!important;
        left: 35%!important;
        -webkit-animation: 4s infinite normal tada;
                animation: 4s infinite normal tada;
 
    }
    

    .card-padding{
        width: 30%!important;
        margin: 0 5px;
    }
    
}

.elemento-resaltante{
    z-index: 10001 !important;
    position: relative;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
}

.elemento-resaltante2{
    z-index: 10001 !important;
    position: relative;
    background-color: white;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 1rem 2rem 4rem 0.5rem #383535;    
}


.z-index-custom-sa{
    z-index: 10002!important;
}

.tooltip-width{
    width: auto!important; 
}

.placeholder-item {
    box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
    border-radius: 4px;
    height: 200px;
    position: relative;
    overflow: hidden;
}

.placeholder-item::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
    -webkit-animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
            animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}

@-webkit-keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

@keyframes load {
    from {
        left: -150px;
    }
    to   {
        left: 100%;
    }
}

.video:empty {
    width: 315px;
    height: 250px; 
    cursor: progress; 
    background: 
      linear-gradient(0.25turn, transparent, #fff, transparent),
      linear-gradient(#eee, #eee),
      radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
      linear-gradient(#eee, #eee);  
    background-repeat: no-repeat;
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; 
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    -webkit-animation: loadin_ 1.5s infinite; 
            animation: loadin_ 1.5s infinite;
  }
  
  @-webkit-keyframes loadin_ {  
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
  
  @keyframes loadin_ {  
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
  
iframe{
    display: block;
    margin: 0 auto;
}  
#__APP_ROOT__, .root{
    display: flex!important;
    justify-content: center!important;
}
.embed{
    display: flex!important;
    justify-content: center!important;
}
  
.bb-3{
    border-bottom: 3px solid black!important;
}

.br-3{
    border-right: 3px solid black!important;    
}

/*DOWNLOAD PLANTILLA*/

.download-plantilla{
    -webkit-animation: downloadPlantilla 2s ease 0s infinite normal none;
            animation: downloadPlantilla 2s ease 0s infinite normal none;
    /* background-color: antiquewhite; */
    /* background-color: #0ff775; */
    background-color: #f7ff00;
    border-radius: 10px;
    width: 35px;
    margin-left: 15px;
    padding: 7px 7px;
    font-weight: bold;
    color: black;
    /* color: red; */; 
    box-shadow: 0px 2px 11px 10px #fffdf4;
    border: 1px solid #ed00004a;
    
}
@media (min-width: 768px) {

    .download-plantilla{
        width: 11rem;
        margin-left: 20px;
        padding: 4px
    }
}

@-webkit-keyframes downloadPlantilla {
	0% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		        transform: scale(1);
		-webkit-transform-origin: center center;
		        transform-origin: center center;
	}

	10% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: scale(0.91);
		        transform: scale(0.91);
	}

	17% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: scale(0.98);
		        transform: scale(0.98);
	}

	33% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: scale(0.87);
		        transform: scale(0.87);
	}

	45% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes downloadPlantilla {
	0% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		        transform: scale(1);
		-webkit-transform-origin: center center;
		        transform-origin: center center;
	}

	10% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: scale(0.91);
		        transform: scale(0.91);
	}

	17% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: scale(0.98);
		        transform: scale(0.98);
	}

	33% {
		-webkit-animation-timing-function: ease-in;
		        animation-timing-function: ease-in;
		-webkit-transform: scale(0.87);
		        transform: scale(0.87);
	}

	45% {
		-webkit-animation-timing-function: ease-out;
		        animation-timing-function: ease-out;
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

/* 404 Error Page */
#oopss {
    background: linear-gradient(-45deg, #6dc0ae, #4ab8a0);
    /* background: linear-gradient(-45deg, rgba(255,243,0, 1), rgba(239,228,0, 1)); */
    position: fixed;
    left: 0px;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 1.5em;
    z-index: 10000;
}
#error-text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Shabnam', Tahoma, sans-serif;
    color: #000;
    direction: rtl;
}

.nf-img {
    margin: 0px auto 20px;
    height: 342px;
}
.nf-span {
    position: relative;
    font-size: 3.3em;
    font-weight: 900;
    margin-bottom: 50px;
}

.p-a {
    font-size: 19px;
    margin: 30px 0 15px 0;
}  
.p-b {
    font-size: 15px;
}  
        
.back {
    background: #fff;
    color: #000;
    font-size: 30px;
    text-decoration: none;
    margin: 2em auto 0;
    padding: .7em 2em;
    border-radius: 500px;
    box-shadow: 0 20px 70px 4px rgba(0, 0, 0, 0.1), inset 7px 33px 0 0px #6dc0ae;
    /* box-shadow: 0 20px 70px 4px rgba(0, 0, 0, 0.1), inset 7px 33px 0 0px #fff300; */
    font-weight: 900;
    transition: all 300ms ease; 
}

.back::hover {
    -webkit-transform: translateY(-13px);
            transform: translateY(-13px);
    box-shadow: 0 35px 90px 4px rgba(0,0,0, .3), inset 0px 0 0 3px #000;
}

.modal.border.fade{
    background-color: #0006!important;
}

.w-32-pc{
    width: 100%;
}

@media (min-width: 768px) {
    .w-32-pc{
        width: 48%;
    }
}
@media (min-width: 992px) {
    .w-32-pc{
        width: 32%;
    }
}

/*
*TABLES
*/
/*Datatables*/


.rdt_TableCell, .rdt_ExpanderRow, .rdt_TableRow > div{
    --bs-text-opacity: 1;
    color: var(--bs-emphasis-color) !important;
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
    padding-top: .5rem;
  }
  .rdt_TableRow > div > button > svg{
    color: var(--bs-emphasis-color) !important;
  }
  .rdt_ExpanderRow{
    padding: 0;
  }
  
  .rdt_TableHeadRow > div {
    --bs-text-opacity: 1;    
    --bs-bg-opacity: 1;
    background-color: #343a40!important;
    background-color: var(--first-color)!important;
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  
  }
  .rdt_TableHeadRow > div > div{
    color: #fff !important;
  }
  
  /* .rdt_TableCol{ */
    /* --bs-text-opacity: 1;
    color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
    --bs-bg-opacity: 1;
    background-color: var(--bg-bd-primary)!important;
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; */
  /* } */
  
  .rdt_Pagination{
    --bs-text-opacity: 1;
    color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
    --bs-bg-opacity: 1;
    background-color: var(--bg-bd-primary)!important;
    border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
